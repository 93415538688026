<script setup>
import { fa } from "vuetify/locale"

const props = defineProps(["card"])

const item = props.card
const authDialog = inject("authDialog")
const user = useUser()
const likeLoading = ref(false)

function textLenControl(text, type) {
  if (type == "title" || type == "location") {
    if (text.length >= 20) {
      return text.substring(0, 20) + "..."
    }
    return text
  } else if (type == "desc") {
    if (text.length >= 28) {
      return text.substring(0, 28) + "..."
    }
  }
}

async function toggleLike() {
  if (!user.value) {
    authDialog.value = true
    return
  }

  likeLoading.value = true
  try {
    const data = await $fetch(`/api/adverts/${item.id}/toggle-like`, {
      method: "POST",
    })

    item._liked = data == "Liked"
    likeLoading.value = false
  } catch (e) {
    console.log(e)
  }
}
</script>
<template>
  <v-col cols="12" md="3" lg="3" sm="6" xm="6" style="min-width: 276px">
    <v-card :to="`/ad/${item.id}`" :ripple="false">
      <v-img
        height="270px"
        v-if="item.images"
        :src="item.images[0].thumbnail_path"
        cover
        position="center"
      ></v-img>
      <v-card-title>
        <div class="d-flex justify-space-between">
          <div>
            {{ item.price }}$ <span v-if="item.price2 != 0">, {{ item.price2 }}$</span>
          </div>
          <v-progress-circular
            v-if="likeLoading"
            indeterminate
            color="primary"
            size="24"
            width="2"
          />
          <IconHeart
            v-else
            @click.prevent="toggleLike"
            style="cursor: pointer; user-select: none"
            :fill="!item._liked ? 'none' : '#7DA1FF'"
            :stroke="!item._liked ? '#919191' : '#7DA1FF'"
          />
        </div>
        <div>{{ textLenControl(item.title, "title") }}&nbsp;</div>
      </v-card-title>
      <v-card-subtitle class="d-flex flex-column ga-2 text-subtitle-1 mb-2">
        <div>{{ textLenControl(item.description, "desc") }}&nbsp;</div>
        <div>
          <v-icon icon="icon-map-pin"></v-icon> {{ textLenControl(item.location, "location") }}
        </div>
        <div class="text-subtitle-2">{{ $coolDate2(item.last_status_changed_at) }}</div>
      </v-card-subtitle>
    </v-card>
  </v-col>
</template>
